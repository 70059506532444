import { connect } from "react-redux"
import React, { Component } from "react"
import { pure, compose } from "recompose"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Info from "../components/onlinesupport/Info"

import { withStyles } from "@material-ui/core/styles"

import * as masterActions from "../actions/master"

type Props = {
  requestProcess: Function
  completeProcess: Function
  isProcessing: boolean
  classes: any
}

type State = {}

class OnlineSupportPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      news: [],
      stories: [],
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="オンラインサポート" />
        <Info />
      </Layout>
    )
  }
}

const styles: any = (theme: any) => ({
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1920,
    paddingTop: 0,
  },
})

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master

  return {
    isProcessing,
    wpModel: state.rootReducer.wordpress,
  }
}

const connector = connect(mapStateToProps, { ...masterActions })

export default compose(pure, withStyles(styles), connector)(OnlineSupportPage)
