import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Image from "../image"
import { lineHeight } from "@material-ui/system"
import { withAssetPrefix } from "gatsby"

type Props = {
  classes: any
}

class OnlinesupportInfo extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section className={classes.background}>
        <div
          id="business"
          className={classNames(classes.componentWrapper, "title-wrapper")}
        >
          <h1 className={classes.title}>オンラインサポートサービス</h1>
        </div>
        <div className={classNames(classes.catchcopy, "pt40")}>
          <div className={classes.strong}>各分野の専門家</div>
          <div>が皆さんの悩みにオンラインで直接答えてくれます</div>
        </div>
        <div>
          <div className="pt30">
            <ul className={classes.list}>
              <li>サッカーやフットサルに関すること</li>
              <li>栄養面や食事に関すること</li>
            </ul>
          </div>
        </div>
        <div className="pt40" id="online">
          <img width="400" src="/assets/images/smartphone.svg" />
        </div>
        <div className={classNames(classes.catchcopy, "pt40")}>
          <div className={classes.strong}>まずは友だち登録からスタート！</div>
        </div>
        <div className="pt40">
          <div className="mb10">↓ここをクリック↓</div>
          <a href="https://lin.ee/iof0TKi">
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
              height="54"
              border="0"
            />
          </a>
        </div>
        <div className="pt30">
          <ol className={classes.list}>
            <li>LINEを使って友だち登録を行います</li>
            <li>あなたにピッタリなアドバイサーをご紹介いたします</li>
            <li>アドバイザーに現在聞きたことを質問してください</li>
            <li>アドバイザーから返信があります</li>
          </ol>
        </div>
        <div className="pt40">
          <hr />
          <div className={classNames(classes.subtitle, "mt40")}>
            アドバイザー
          </div>
          <div className={classes.itemWrapper}>
            <img src="/assets/images/yongde.jpg" className={classes.img} />
            <div className={classes.user}>
              <div className={classes.name1}>CHUNG YONGDE</div>
              <div className={classes.name2}>
                チョン ヨンデ<span className={classes.position}>Director</span>
              </div>
              <div className={classes.description}>
                選手時代は浦項スティーラーズ(韓国Kリーグ)、名古屋グランパス、セレッソ大阪、川崎フロンターレ、横浜FC、コンサドーレ札幌でプレー。Jリーグ通算155試合に出場。
                <br />
                指導歴は川崎フロンターレ育成普及コーチやU-13
                15コーチ、横浜FC、カターレ富山、ギラヴァンツ北九州トップコーチ、名古屋グランパスU15監督、栃木シティトップチーム監督などでJ1昇格を３度経験している。
              </div>
            </div>
          </div>
          <div className={classes.itemWrapper}>
            <img src="/assets/images/sakai.jpg" className={classes.img} />
            <div className={classes.user}>
              <div className={classes.name1}>RYOTARO SAKAI</div>
              <div className={classes.name2}>
                酒井 遼太郎<span className={classes.position}>Coach</span>
              </div>
              <div className={classes.description}>
                現役フットサル選手 立川・府中アスレティックFC所属
                <br />
                エスポラーダ北海道→立川府中アスレティックFC
                <br />
                3歳からサッカーを始め、高校選択にて北海道、帯広北高校に進学。
                その後、サッカーで札幌大学へ進学しスポーツ教育を専攻その後、遊びで始めたフットサルに転向、のちプロとなった。
                <br />
                指導経歴 リベルタサッカースクール エスポラーダスクール
                その他スクールにコーチとして派遣され教育、育成してきました。
              </div>
            </div>
          </div>
          <div className={classes.itemWrapper}>
            <img src="/assets/images/uemura.jpg" className={classes.img} />
            <div className={classes.user}>
              <div className={classes.name1}>ATSUYA UEMURA</div>
              <div className={classes.name2}>
                上村 充哉<span className={classes.position}>Coach</span>
              </div>
              <div className={classes.description}>
                現役フットサル選手 立川・府中アスレティックFC所属
                <br />
                湘南ベルマーレ→立川・府中アスレティックFC
                <br />
                小学校からフットサルを始め、2017年には日本代表に選出。
              </div>
            </div>
          </div>
          <div className={classes.itemWrapper}>
            <img src="/assets/images/yamanaka.jpg" className={classes.img} />
            <div className={classes.user}>
              <div className={classes.name1}>TOSHIRO YAMANAKA</div>
              <div className={classes.name2}>
                山中 登士郎<span className={classes.position}>Coach</span>
              </div>
              <div className={classes.description}>
                中央大学サッカー部卒業、現中央大学サッカー部コーチ
                <br />
                山梨学院高校サッカー部では高校サッカー選手権大会に出場、中央大学サッカー部に進学して4年次にはキャプテンを務める。卒業後、中央大学サッカー部のコーチとなる。
                MIFAサッカースクールの立ち上げサポートを行なっている。
              </div>
            </div>
          </div>
          <div className={classes.itemWrapper}>
            <img src="/assets/images/otahara.jpg" className={classes.img} />
            <div className={classes.user}>
              <div className={classes.name1}>HIROYUKI OTAHARA</div>
              <div className={classes.name2}>
                太田原 裕幸
                <span className={classes.position}>
                  アスレティックトレーナー/鍼灸師
                </span>
              </div>
              <div className={classes.description}>
                経歴　東京ヴェルディ（トップチーム、ユース）、日テレベレーザ、横浜マリノス、男子サッカー日本代表（世代別）
                ※FIFA U-17ワールドカップ2019ブラジル大会　帯同
                ※日本オリンピック委員会強化医科学スタッフ（2017-2019）
                ※プロサッカー選手パーソナルトレーナー（国内／海外）
                資格　鍼伮師/日本スポーツ公認アスレティックトレーナー/米国NASM認定
                /Performance Enhancement
                Specialist/HYPERICE認定トレーナー/ViPRインストラクター/JFA認定スポーツ救命ライセンス/Core
                Power Yoga　CPY
              </div>
            </div>
          </div>
          <div className={classes.itemWrapper}>
            <img src="/assets/images/kimura.jpg" className={classes.img} />
            <div className={classes.user}>
              <div className={classes.name1}>KONOMI KIMURA</div>
              <div className={classes.name2}>
                木村 好珠
                <span className={classes.position}>
                  精神科医/産業医/健康スポーツ医
                </span>
              </div>
              <div className={classes.description}>
                東邦大学医学部医学科を卒業後、慶應大学病院勤務。
                現在は、東北で精神科医として地域医療に従事、また都内企業の産業医を行いながら、ブラインドサッカー東京パラリンピック日本代表メンタルアドバイザーおよびレアル・マドリード・ファンデーションメンタルアドバイザーを行なっている。
                メンタルケアが注目されるようになった今でも、対象は現役プロアスリートのみで、幼少期からのメンタル育成は皆無と言っていい。また、メンタルトレーナーはほとんど元アスリートであり、心理学や精神医学からアプローチしているものは数少ない。
                そこで、海外の情報と心理学・精神医学を織り交ぜながら、小さい頃からのメンタル教育『メンタルの予防医学』を日本に広めていくために日々奮闘中である。
              </div>
            </div>
          </div>
          <div className={classes.itemWrapper}>
            <img
              src="/assets/images/hayashi_logo.png"
              className={classes.img}
            />
            <div className={classes.user}>
              <div className={classes.name1}>NAMIE HAYASHI</div>
              <div className={classes.name2}>
                林 南恵<span className={classes.position}>管理栄養士</span>
              </div>
              <div className={classes.description}>
                東京栄養食糧専門学校を卒業後、委託給食会社に入社し介護施設で勤務。
                <br />
                現在は保育園に勤務し献立作成や調理、子どもたちの食育を行っています。子どもたちが給食を食べて「おいしい」も喜ぶ姿や笑顔にやりがいを感じながら子どもたちの成長のサポートをしています。
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="pt40">
          <div className="mb10">↓ここをクリック↓</div>
          <a href="https://lin.ee/iof0TKi">
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
              height="54"
              border="0"
            />
          </a>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    paddingTop: 50,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #333",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 90,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  list: {
    paddingTop: 12,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  catchcopy: {
    color: "#0014FF",
    fontSize: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  strong: {
    fontSize: 50,
    paddingRight: 20,
    lineHeight: 1.2,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      marginBottom: 20,
    },
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    paddingBottom: 20,
  },
  img: {
    width: 200,
    height: 200,
    objectFit: "cover",
    objectPosition: "50% 0",
    paddingRight: 20,
  },
  itemWrapper: {
    paddingTop: 40,
    paddingBottom: 40,
    display: "flex",
    flexWrap: "wrap",
  },
  user: {
    maxWidth: 700,
  },
  name1: {
    fontSize: 30,
    fontFamily: "Helvetica",
    fontWeight: "bold",
  },
  name2: {
    fontSize: 20,
    marginTop: 20,
  },
  position: {
    marginLeft: 20,
    fontSize: 16,
  },
  description: {
    marginTop: 20,
    fontSize: 13,
    fontFamily: "Helvetica",
    fontWeight: "bold",
    letterSpacing: 0.25,
  },
})

export default withStyles(styles)(OnlinesupportInfo)
